
export default {
  inheritAttrs: false,

  props: {
    showEasementsNavigation: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    clickUpgrade() {
      window.open(this.$t("FREMIUM_CTA_ALT_URL"), "_blank");
    },

    clickEasements() {
      this.$router.push({ 
        name: "explore-property-id-easements", 
        params: {
          id: this.$route.params.id
        } 
      });
    },
  }
};
